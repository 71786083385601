<template>
  <div class="page-wrap login">
    <div class="version-info">Version : 0.0.{{ versionInfo }}</div>
    <div class="session-form-hold">
      <!-- 이미지영역 -->
      <!-- <LoginImage /> -->

      <!-- 로그인영역 -->
      <LoginForm @signIn="signAction" @openPassWordFind="openPassWordFind" />
    </div>

    <!-- 비밀번호 찾기 -->
    <DialogFindPw ref="refDialogFindPw" @saveComplete="pwFindSendComplete" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LoginImage from "./comp/LoginImage.vue";
import LoginForm from "./comp/LoginForm.vue";
import DialogFindPw from "@/components/commonV2/Dialog/DialogFindPW.vue";
export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  components: {
    LoginImage,
    LoginForm,
    DialogFindPw,
  },
  data() {
    return {
      reqData: {
        loginId: "",
        password: "",
      },
      vCode: process.env.VUE_APP_VERSION_CODE,
      vDate: process.env.VUE_APP_RELEASE_DATE
    };
  },
  methods: {
    signAction(e) {
      console.log("", e);
      e.userAgent = navigator.userAgent;
      this.signIn(e).then(() => {});
    },
    openPassWordFind() {
      this.$refs.refDialogFindPw.Open();
    },
    pwFindSendComplete() {},
    ...mapActions(["signIn"]),
  },
  computed: {
    ...mapGetters(["loggedInUser", "error"]),
    versionInfo() {
      return `(${this.vCode} / build Date : ${this.vDate})`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/octavia-design-system/variables/colors.scss";
.version-info {
  position: fixed;
  bottom: 10px;
  color: rgb(255, 255, 255);
}
.page-wrap.login {
  position: fixed;
  background-color: #dadada !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  .session-form-hold {
    // width: 100%;
    // max-width: 500px;
    // height: 540px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .intro-area {
    padding: 40px 10% 40px 0;
    width: 500px;
    text-align: center;
    .intro-title {
      font-size: 60px;
      color: #ffffff;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 40px;
      span {
        font-size: 40px;
        b {
          color: $point;
          font-weight: 600;
        }
      }
      img {
        width: 400px;
      }
    }
    .top_logo {
      width: 250px;
    }
    .broccoli {
      width: 300px;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      width: calc(100% - 150px);
      color: #ffffff;
      padding: 30px;
      font-size: 30px;
      line-height: 45px;
      font-weight: 700;
      vertical-align: middle;
    }
  }

  .v-sheet.v-card {
    width: 500px;
    height: 540px;
  }
}
.hGap {
  height: 25px;
}
.text-center {
  text-align: center;
}
</style>
