<template>
  <base-card>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="primary"
    />
    <v-card-text class="text-center">
      <img src="@/assets/bi_logo.png" />
      <v-form ref="form">
        <v-text-field
          ref="loginId"
          v-model="loginId"
          :label="$t('login.inputID')"
          :rules="[loginIdRules.required]"
          required
        />

        <v-text-field
          v-model="password"
          name="input-10-2"
          :label="$t('login.inputPW')"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :counter="15"
          :rules="[passwordRules.required]"
          @click:append="show = !show"
          @keyup.enter="enterInput"
          required
        />
        <div class="hGap"></div>
        <v-btn class="mb-4" block color="primary" dark @click="formSubmit">
          <v-icon left> mdi-login </v-icon>
          {{ $t("login.loginBtn") }}
        </v-btn>
        <div class="d-flex justify-around flex-wrap">
          <v-btn text small color="primary" class="mb-2" @click="FindPW">
            {{ $t("login.forgetPW") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </base-card>
</template>
<script>
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  data() {
    return {
      show: false,
      loading: false,
      loginId: "",
      password: "",
      loginIdRules: rulesService.loginIdRules(),
      passwordRules: rulesService.passwordRules(),
    };
  },
  mounted() {
    // 개발모드에서 처리
    if (process.env.NODE_ENV === "development") {
      this.loginId = "m@predict.kr";
      this.password = "1234!";
    }
  },
  methods: {
    formSubmit() {
      if (!this.$refs.form.validate()) return;
      // console.log("formSubmit");
      // return;
      this.$emit("signIn", this);
      // if (!this.$helper.validateEmail(this.loginId)) {
      //   this.$eventBus.$emit("snackbar", {
      //     message: this.$t("login.snackbar.userId"),
      //   });
      //   this.$refs.loginId.focus();
      //   return;
      // }
      // this.login({ loginId: this.loginId, password: this.password });
    },
    FindPW() {
      this.$emit("openPassWordFind");
    },
    enterInput() {
      this.formSubmit();
    },
  },
};
</script>
<style lang="">
</style>