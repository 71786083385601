<template>
  <div class="intro-area">
    <img
      class="top_logo"
      src="@/assets/images/login/login_top_logo.png"
      alt=""
    />
    <h1 class="intro-title">
      <img
        class="title"
        src="@/assets/images/login/doctor_portal_title.png"
        alt=""
      />
    </h1>
    <img class="broccoli" src="@/assets/images/login/login_image.png" alt="" />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
</style>